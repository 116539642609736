import useResponsive from '../../libraries/hooks/useResponsive'
import LayoutWithSidebar from './LayoutWithSidebar'
import LayoutDesktop from './LayoutDesktop'
import BottomNav from '../commons/BottomNav';
import LayoutSmall from './LayoutSmall';

export default function LayoutResponsiveWSmall({ children, header, right, left, logo, loading, container }) {
    const isMobile = useResponsive();
    const headerDesktop = {
        ...header,
        className: "overflow-y-hidden " + (header && header.className ? header.className : ""),
    }


    return isMobile ? (
        <LayoutSmall
            header={header}

        >
            {children}
            <BottomNav />
        </LayoutSmall>
    ) : (
        <LayoutDesktop>
            <LayoutSmall
                header={headerDesktop}
                
            >
                {children}
                {/* <BottomNav /> */}
            </LayoutSmall>
        </LayoutDesktop>
    )
}

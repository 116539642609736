import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import Loader from '../../components/commons/Loader';
import ProductCard from '../../components/customs/ProductCard';

import productsAction from '../../context/products/actions';
import servicesAction from '../../context/services/actions';
import favorsAction from '../../context/favors/actions';
import transactionsAction from '../../context/transactions/actions';
import companiesAction from '../../context/companies/actions';
import Swal from 'sweetalert2';

import { capitalize, toBool } from '../../libraries/utils';

import Instructive from '../../components/commons/Instructive';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';

import NewProductModal from '../../components/customs/NewProductModal';
import Icon from '../../libraries/icons';
import LayoutResponsive from '../../components/layout/LayoutResponsive';
import LayoutDesktop from '../../components/layout/LayoutDesktop';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.scrollContainerRef = React.createRef();
    this.state = {
      products : [],
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
      companiesIds: null,
      newProductModalOpen: false,
      loading: true,
    }
  }

  componentDidMount() {
    console.log('OFFER PAGE');
    let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
    this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getProducts)  
  }

  getProducts = async ()=> {
    console.log("getProducts", this.state.unique_id)
    await this.props.onGetProducts(
      { 
        status: [config.STATUS.PRODUCTS.AVAILABLE], 
        unique_id: this.state.unique_id, 
        order_by: "products__created_at", 
        order_direction: 'DESC'
      });
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      console.log("getProducts", products.items)
      this.setState({
        loading: false,
        products: products.items
                        .filter(p => p?.user && p?.user?.id !== this.props.auth.user.id)
                    //.filter(p => p.owner !== this.props.auth.user.id)    
      })
    }    
  }

  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getData)
  }

  // changeOrigin = (e) => {
  //   this.setViewAll(e.value);
  // }

  onSubmit = (value) => {
    console.log('VALUES', value);
    this.setViewAll(toBool(value));
  }

  goToNewProduct = () => {
    this.setState({newProductModalOpen: true})
    //history.push({
    //  pathname: config.ROUTES.ADD,
    //  state:{
    //    fromPath: config.ROUTES.USER.INVENTORY,
    //  }
    //})
  }

  render() {
    const { products, loading, services, favors, requests, viewAll } = this.state;
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];
    const tabs = [
      {
        
          name: capitalize(this.t("productos")),
          onClick: () => {},//{history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)},
          active: true
      },
        {
          name: capitalize(this.t("favores")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.FAVORS)},
        },
        {
          name: capitalize(this.t("peticiones")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
        },      
    ]
    return (
      <LayoutResponsive
        // main={{ className: "pb-[48px]"}}
        main={{ className: "pb-14" }}

        header={{ 
          logo: true,
          right: { icon: 'plus', action: this.goToNewProduct, buttonClassName: "bg-primary" },
          // content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
          // tabs: [
          //   {
          //     name: capitalize(this.t("productos")),
          //     onClick: () => {},//{history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)},
          //     active: true
          //   },
          //   {
          //     name: capitalize(this.t("favores")),
          //     onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.FAVORS)},
          //   },
          //   {
          //     name: capitalize(this.t("peticiones")),
          //     onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
          //   },
          // ]
        }}
        loading={loading}
        container={{ className: "px-0"}}
      >
      <ToastContainer/>
      <div className="w-full mx-auto px-5 border-t border-gray-100 ">        
        <Instructive slides={[
            {
              title:"Sección préstamos",
              body:"Pedí prestado productos o favores a tus compañeros de trabajo canjeando tus Puntos Shary ganados. Y ganá Puntos de Impacto porque al pedir prestado estás ayudando al planeta.",
              img: "loans",
            },
            {
              title:"Cómo pedir algo prestado",
              body:"Desplázate por los productos y favores que ofrecen tus compañeros de trabajo y haz clic en lo que necesites.",
              img: "howtoborrow",
            },
            {
              title:"Cómo prestar algo",
              body:"Hacé clic en el signo +, subí lo que quieras compartir y empezá a ganar Puntos Shary y puntos de Impacto.",
              img: "howtolend",
            },
            {
              title:"¿No podés encontrar lo que necesitás?",
              body:"Podés agregar una petición  para que tus compañeros de trabajo puedan ver lo que necesitás y subir lo que buscás.",
              img: "cantfind",
            },
          ]}
          instructive={"show_offers"}
        >
        <NewProductModal t={this.t} open={this.state.newProductModalOpen} setOpen={(open) => this.setState({newProductModalOpen:open})}/>

        <section className=" w-full mx-auto ">
          <div className="mb-5 scrolling-wrapper mx-5">
            {tabs.map((tab, index) => {
                return(
                    <div
                      key={index}
                      className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none" + (index === 0 ?  " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                      onClick={tab.onClick}
                    >
                      <h5 className='whitespace-nowrap'>{this.t(tab.name)}</h5>
                    </div>
                )
                })

              }

          </div>
          { this.props.products.loading && (<Loader/>) }
          {!this.props.products.loading && products.length === 0 && (
             <div className='flex flex-col items-center gap-5 mt-5 text-neutral'>
              <Icon className="h-16 w-16" name="sad" />
              <p className="">{this.t("No hay productos disponibles")}</p>
            </div>
          )}
          <div className="flex flex-col gap-4 pt-5">
            { products && products.map((product, index) => {
                return (
                  <ProductCard 
                    key={'p' + index}
                    route={{
                      pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
                      state:{
                        fromPath: config.ROUTES.HOME_OPTIONS.PRODUCTS
                      }
                    }}
                    product={product}
                  />
                )
              })
            }
          </div>
        </section>
        
        </Instructive>
      </div>

      {/* <BottomNav/> */}
      </LayoutResponsive>  
    ) 
  }
} 

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    services: state.services.list,
    favors: state.favors.list,
    requests: state.transactions.list,
    company: state.companies.current,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetServices: (params) => dispatch(servicesAction.getAll(params)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
    onGetFavors: (params) => dispatch(favorsAction.getAll(params)),
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Products));


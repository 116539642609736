import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';
import { isEmptyObject, capitalize } from '../../libraries/utils';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import IconPoints from '../../components/customs/IconPoints';
import IconCoins from '../../components/customs/IconCoins';
import Avatar from '../../components/commons/Avatar';
import Icon from '../../libraries/icons';
import LayoutResponsive from '../../components/layout/LayoutResponsive';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

// import alliances from '../../assets/icons/ODS/alliances.png';
// import education from '../../assets/icons/ODS/education.png';
// import energy from '../../assets/icons/ODS/energy.png';
// import gender_equality from '../../assets/icons/ODS/gender-equality.png';
// import health from '../../assets/icons/ODS/health.png';
// import hunger from '../../assets/icons/ODS/hunger.png';
// import industry from '../../assets/icons/ODS/industry.png';
// import inequalities from '../../assets/icons/ODS/inequalities.png';
// import peace from '../../assets/icons/ODS/peace.png';
// import poverty from '../../assets/icons/ODS/poverty.png';
// import production from '../../assets/icons/ODS/production.png';
// import sustainable from '../../assets/icons/ODS/sustainable.png';
// import terrestrial_eco from '../../assets/icons/ODS/terrestrial-eco.png';
// import undersea from '../../assets/icons/ODS/undersea.png';
// import water from '../../assets/icons/ODS/water.png';
// import weather from '../../assets/icons/ODS/weather.png';
// import work from '../../assets/icons/ODS/work.png';

class AboutODS extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {}
  }
  componentDidMount() {
    console.log("ODS PAGE")
    const state = this.props.location.state;
    if (!isEmptyObject(state)) {
      this.setState({
        route: state.from,
        prevFrom: state.prevFrom
      });
    }
    
  }

  goBack = () => {
    history.push({
      pathname: this.state.route || config.ROUTES.HOME_OPTIONS.ACTIONS,
      state: { from: this.state.prevFrom || config.ROUTES.HOME_OPTIONS.ACTIONS }});
  }

 
  render() {
    const { ods } = this.state;
    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-white text-primary flex justify-start p-3",
          left: { icon: 'arrow_left', action: this.goBack },
          title: capitalize(this.t("Sobre los ODS"))
        }}
        container={{ className: "px-0"}}
      > 
          <div className='flex flex-col h-full'>
            <section className="bg-success bg-opacity-20 shadow px-3 py-2">
              <div className="flex items-center">
                <div>
                  <h3>17 Objetivos para las personas y para el planeta</h3>
                  <div className="text-sm mt-2">
                  Los Objetivos de Desarrollo Sostenible (ODS) constituyen un llamamiento universal a 
                  la acción para poner fin a la pobreza, proteger el planeta y mejorar las vidas y las 
                  perspectivas de las personas en todo el mundo. En 2015, todos los Estados Miembros 
                  de las Naciones Unidas aprobaron 17 Objetivos como parte de la Agenda 2030 para el 
                  Desarrollo Sostenible, en la cual se establece un plan para alcanzar los Objetivos en 15 años.
                  </div>
                </div>
              </div>
            </section>
                
            <section className="container-fluid p-4 flex-1">
                {config.ODS.map((o,i) => (
                    <div key={i} className='flex mb-3 items-center'>
                        <img src={o.squareIcon} className='w-[68px] mr-1'/>
                        <p className='text-sm px-1'>{o.text}</p>
                    </div>
                ))}
            </section>            
          </div>
      </LayoutResponsiveWSmall>
    ) 
  }
}

export default withTranslation()(AboutODS);


import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ToastContainer, notify } from "../../libraries/notifications";

import { history } from '../../routes';
import config from "../../config";

import transactionsAction from '../../context/transactions/actions';

import BottomNav from '../../components/commons/BottomNav';
import Loader from '../../components/commons/Loader';
import RequestElement from "../../components/customs/RequestElement";
import { capitalize, toBool } from '../../libraries/utils';

import Instructive from '../../components/commons/Instructive';
import Icon from '../../libraries/icons';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';

import NewProductModal from '../../components/customs/NewProductModal';
import LayoutResponsive from "../../components/layout/LayoutResponsive";

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
      requests: [],
      search: "",
      newProductModalOpen: false,
      loading: true,
    };
  }

  componentDidMount() {
    console.log('Requests');
    console.log("this.props.location.state.fromYoBusco", this.props.location.state)
    if (this.props.location.state) {
      if(this.props.location.state.fromYoBusco){
        this.setState({onlyRequestsFromUser: true})
      }
    }

    this.getRequests();
  }

  getRequests = async () => {
    const {search} = this.state
    const params = {
      type: [
        config.TRANSACTIONS.REQUEST.TYPE,
      ],
      owner: this.props.auth.user.unique_id,
      order_by: "created_at",
      order_direction: 'DESC'
    }

    if (search && search !== ""){
      //params.name = `%${search.toLowerCase()}`
      //params.where = {"json_data":[]};
      //params.where.json_data.push({"field":"details", "value":`${search.toLowerCase()}`})
    }
    
    await this.props.onGetAllRequests(params);
    const { requests } = this.props;
    if (requests.error) {
      notify(this.t(requests.error.message));
    } else {
      let r = []
      if(this.state.onlyRequestsFromUser){
        r = requests.items.filter(item => item.created_by === this.props.auth.user.id)
      } else {
        r = requests.items.filter(item => item.created_by !== this.props.auth.user.id)        
      }
      if (search && search !== ""){
        r = r.filter(item => item.json_data.details.toLowerCase().includes(search) || item.json_data.category.name.toLowerCase().includes(search))
      }
      this.setState({ requests: r, loading: false });
    }
  };

  searchClear = (form) => {
    form.change('search', undefined);
    this.searchOnClick({search: ''});
  }

  searchOnClick = async (values) => {
    console.log("Search on Click", values.search);
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, () => this.getRequests() );
  };

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.OFFER }});
  }

  gotoAdd = () => {
    history.push({
      pathname: config.ROUTES.ADD,
      state:{
        fromPath: config.ROUTES.REQUESTS.LIST,
      }
    })
  }
  goToNewProduct = () => {
    this.setState({newProductModalOpen: true})
    //history.push({
    //  pathname: config.ROUTES.ADD,
    //  state:{
    //    fromPath: config.ROUTES.USER.INVENTORY,
    //  }
    //})
  }


  render() {
    const { products, services, favors, requests, viewAll, loading } = this.state;
    const tabs = [
      {
        
          name: capitalize(this.t("productos")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)},
          active: true
      },
        {
          name: capitalize(this.t("favores")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.FAVORS)},
        },
        {
          name: capitalize(this.t("peticiones")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
        },      
    ]
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];
    return (
      <LayoutResponsive
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          right: { icon: 'plus', action: this.goToNewProduct, buttonClassName: "bg-primary" },
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
          tabs: [
            {
              name: capitalize(this.t("productos")),
              onClick: () => {{history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)}},
            },
            {
              name: capitalize(this.t("favores")),
              onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.FAVORS)},
            },
            {
              name: capitalize(this.t("peticiones")),
              onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
              active: true
            },
          ]
        }}
        loading={loading}
        container={{ className: "px-0"}}
      >
      <ToastContainer/>

      <div className="w-full mx-auto">
        <Instructive slides={[
          {
            title:"Economía Circular",
            body:"Pedí prestado lo que necesitás canjeando tus puntos Shary. ¡Y no te olvides de publicar lo que quieras ofrecer, asi ganás puntos cuando te lo pidan!",
            img: "cantfind"
          }]} instructive={"show_offers"}> 
        <NewProductModal t={this.t} open={this.state.newProductModalOpen} setOpen={(open) => this.setState({newProductModalOpen:open})}/>
        <section className="pb-10 p-3 w-full mx-auto">
        <div className="scrolling-wrapper mx-5">
            {tabs.map((tab, index) => {
                return(
                    <div
                      key={index}
                      className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none" + (index === 2 ?  " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                      onClick={tab.onClick}
                    >
                      <h5 className='whitespace-nowrap'>{this.t(tab.name)}</h5>
                    </div>
                )
                })

              }

          </div>       
          <div className="flex flex-col gap-4 w-full">
            { this.props.requests.loading && (<Loader/>) }
            {!this.props.requests.loading && requests.length === 0 && (
              <div className='flex flex-col items-center gap-5 mt-5 text-neutral'>
                <Icon className="h-16 w-16" name="sad" />
                <p className="">{this.t("No hay peticiones disponibles")}</p>
              </div>
            )}
            {requests && requests.map((request, index) => {
              return (
                <RequestElement 
                  key={'p' + index}
                    route={
                      {pathname: config.ROUTES.REQUESTS.PREVIEW.replace(":id", request.id)}
                  } 
                  request={request}
                />
              )})
            }
        </div>
          
        </section>
       
        </Instructive>
      </div>

      {/* <BottomNav/> */}
      </LayoutResponsive>  
    ) 
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    requests: state.transactions.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Requests));

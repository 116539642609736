import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import logo from '../../assets/img/logo.png';
import medal from '../../assets/img/medal.png';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';
import MenuItem from '../commons/MenuItem';
import UserProfileImage from '../commons/UserProfileImage';
import IconPointsCircle from '../customs/IconPointsCircle';
import IconCoinsCircle from '../customs/IconCoinsCircle';
import { capitalizePhrase, capitalize } from '../../libraries/utils';
import userActions from '../../context/users/actions';
import prizesActions from '../../context/benefits/actions';
import settingsActions from '../../context/settings/actions'
import notificationsActions from '../../context/notifications/actions';
import AutoQuery from '../commons/AutoQuery';


import { notify } from '../../libraries/notifications';


export default function ProfileMenuDesktop({ header }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [collapseShow, setCollapseShow] = React.useState("close");
  const user = useSelector(state => state.users.auth.user);
  const rewards = useSelector(state => state.users.auth.user.rewards)
  const ranking = useSelector(state => state.users.list.ranking);
  const prizes = useSelector(state => state.benefits.list.items)
  const settings = useSelector(state => state.settings)
  const dispatch = useDispatch();
  const [userRanking, setUserRanking] = useState("N/A");
  const [areNewPrizes, setAreNewPrizes] = useState(false)
  const [mainMenu, setMainMenu] = useState([]);
  const [showingBenefits, setSowingBenefits] = useState(false);
  const [showingPrizes, setSowingPrizes] = useState(false);
  const onGetSettings = async params => dispatch(settingsActions.getAll(params))
  const onGetNotifications = async params => dispatch(notificationsActions.getAll(params))


  const notifications = useSelector(state => state.notifications.list)
  const unReadedNotifications = notifications.items.filter(n => n.readed === 0 && ["notification"].includes(n.type)).length
  const unReadedMessages = notifications.items.filter(n => n.readed === 0 && ["message", "question"].includes(n.type)).length


  const getRanking = () => {
    // await this.props.onGetUsersRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: this.props.auth.user.unique_id });
    if (!ranking.length) {
      dispatch(userActions.getRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: user.unique_id }));
    }
  }

  const getNotifications = async () => {
    // TODO: revisar esto
    const params = { target: user.id };
    await onGetNotifications(params);

    if (notifications.error) {
      console.log(notifications.error.message);
    }
  };

  const getActions = () => {
    if (!prizes.length) {
      dispatch(prizesActions.getAll({ enabled: 1, type: ["prize"], owner: user.unique_id }))
    }
  }

  const getRewards = () => {
    dispatch(userActions.getRewards({ id: user.id, isPublicProfile: false }))
  }

  const getSettings = async () => {
    if (settings.list.items.length || settings.list.loading) return
    const params = {
      type: "sharyco_settings",
      owner: user.unique_id,
    };
    await onGetSettings(params)
    if (settings.error) {
      console.log(settings.error.message);
    }
  }

  const filterSidebar = () => {
    if (!settings.list.items.length) return;
    let menu = config.SIDEBAR.MENU.MAIN;
    let showingBenefits = true;
    let showingPrizes = true
    settings.list.items.forEach((item) => {
      if (
        item.type === "sharyco_settings" &&
        (!item.value || item.value === "false")
      ) {
        switch (item.code) {
          case "show_actions":
            menu = menu.filter(
              (section) => section.route !== config.ROUTES.HOME_OPTIONS.ACTIONS
            );
            break;
          case "show_offers":
            menu = menu.filter(
              (section) =>
                section.route !== config.ROUTES.USER.INVENTORY &&
                section.route !== config.ROUTES.USER.LOANS &&
                section.route !== config.ROUTES.USER.RETURNS
            );
            break;
          case "show_benefits":
            showingBenefits = false;
            break;
          case "show_prizes":
            showingPrizes = false;
            break;
          default:
            break;
        }
      }
    });
    setMainMenu(menu);
    setSowingBenefits(showingBenefits);
    setSowingPrizes(showingPrizes)
  };

  useEffect(() => {
    getRanking()
    getActions()
    getRewards()
    getSettings()
  }, [])

  useEffect(() => {
    if (ranking?.error) {
      notify('Error obteniendo el ranking')
    } else {
      let user_ranking = "N/A"
      if (ranking) {
        ranking.forEach((r, index) => {
          if (r.id === user.id && r?.points !== "0") {
            user_ranking = index + 1;
          }
        })
      }
      setUserRanking(user_ranking)
    }
  }, [ranking, user])

  useEffect(() => {
    if (prizes?.error) {
      notify('Error cargando premios')
    } else {
      const lastSeenPrize = localStorage.getItem("lastSeenPrize")
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
      const areNew = prizes.some(p => (new Date(p.created_at) > new Date(oneWeekAgo)) && (new Date(p.created_at) > new Date(lastSeenPrize)))
      setAreNewPrizes(areNew)
    }
  }, [prizes])

  useEffect(() => {
    filterSidebar()
  }, [settings])

  return (
    <>
      <div
        className={
          "text-primary-content w-72 bg-primary shadow-lg flex flex-col max-h-fit rounded-3xl lg:overflow-y-scroll xl:overflow-y-auto"
        }
      >
        <AutoQuery getData={getNotifications} />

        <div className="pb-14 relative">
          <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-20 rounded-3xl'></div>
          <img src={user?.profile_image} className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' alt="" />
          <div className='flex flex-row px-4 text-center pt-10 z-10 relative'>
            <div className='flex items-end justify-center'>
              <IconCoinsCircle className="sidebar--points" coins={rewards?.total?.coins} />
            </div>

            <div className="flex-1">
              <UserProfileImage data={user} />
              {/*<div className="flex justify-center gap-3 mx-auto">
                  <div className="badge bg-base-100 bg-opacity-20 badge-sm border-none">{user.ancestors?.company?.name}</div> 
                </div>*/}
              <h3>{capitalizePhrase(user?.name)}</h3>
              {typeof user?.rewards?.level === 'number' && <p>Nivel {rewards?.level}</p>}
              <Button
                className="btn-ghost text-secondary btn-xs px-4 mx-auto "
                title={t("Profile")}
                onClick={() => history.push(config.ROUTES.USER.PROFILE)}
              />
            </div>
            <div className='flex items-end justify-center'>
              <IconPointsCircle className="sidebar--points" points={rewards?.total?.points} iconClassName="w-5" />
            </div>
          </div>
        </div>
        <div className='px-4 -mt-8 z-10 relative'>
          <div className="flex gap-3 bg-base-content rounded-xl mb-3 shadow-lg">
            <div className='pl-3 '>
              <div className='relative'>
                <img src={medal} className='w-16' alt="medal" />
                <p className='absolute bottom-1 right-0 left-0 text-center text-2xl font-bold'>{userRanking}</p>
              </div>
            </div>
            <div className='pt-2 pr-4 pb-2 w-full'>
              <div className="w-full flex justify-between flex-col leading-4">
                <h5 className='font-light'>
                  Ranking en
                </h5>
                <div className="flex justify-between items-center">
                  <h5 className="font-bold">{user.ancestors?.company?.name}</h5>
                </div>
              </div>
              <div className='indicator'>
                <ButtonIcon
                  buttonClassName="btn-ghost text-secondary btn-xs flex justify-between w-full p-0 tracking-wider mt-1"
                  className=""
                  onClick={() => history.push(config.ROUTES.USER.RANKING)}
                  text={
                    <div className='flex gap-1 pr-1'>
                      {t(`Ver Ranking${showingPrizes ? " & Premios" : ""}`)}
                      {areNewPrizes && <div className="badge badge-sm bg-error text-white border-none"></div>}
                    </div>}
                  icon='arrow_right'
                />


              </div>
            </div>

          </div>
        </div>
        <div className="flex flex-col text-primary-200 px-4 relative">
          {mainMenu && mainMenu.map((item, index) => {
            let notifications = item.route === config.ROUTES.NOTIFICATIONS
            let messages = item.route === config.ROUTES.MESSAGES

            return (
              <div className="flex flex-row items-center" key={index}>
                <MenuItem key={index} item={item} location={location} />
                {item?.notifications && notifications && !!unReadedNotifications && <div className="badge absolute right-8 badge-sm bg-error text-white border-none">{unReadedNotifications}</div>}
                {item?.notifications && messages && !!unReadedMessages && <div className="badge absolute right-8 badge-sm bg-error text-white border-none">{unReadedMessages}</div>}
              </div>
            )
          })}
        </div>
        {showingBenefits && (
          <div className="px-4 pt-5 py-2">
            <ButtonIcon
              buttonClassName="btn-secondary w-full btn-sm flex-row-reverse gap-2"
              className="w-4 h-4"
              onClick={() => history.push(config.ROUTES.MAIN.BENEFITS)}
              icon="gift"
              text={
                <>
                  {areNewPrizes && <div className="badge badge-sm bg-error text-white border-none"></div>}
                  Canjeá tus créditos
                </>
              }
            />
          </div>
        )}

        <div className="flex justify-start border-t text-md font-light border-solid border-white border-opacity-20 py-2 px-3">
          {/* secondaryMenu && secondaryMenu.map((item, index) => <MenuItem key={index} item={item} location={location} />)*/}
          <ButtonIcon
            buttonClassName="text-secondary btn-ghost btn-xs flex-row-reverse gap-2"
            className="w-4 h-4"
            icon="question"
            text={capitalize(t("help"))}
            onClick={() => history.push({ pathname: config.ROUTES.INFO, state: { from: location.pathname } })}
          />
        </div>

      </div>
    </>
  )
}

import React from 'react';
import NavbarDesktop from './NavbarDesktop';
import Loader from '../commons/Loader';
import ProfileMenuDesktop from './ProfileMenuDesktop';
import PointRanking from './PointRanking';

export default function LayoutDesktop({ main, header, footer, container, loading, children }) {
  return (
    <main className={"h-screen flex flex-col" + (main && main.className ? main.className : "")}>
        {<NavbarDesktop header={header} />}
        {header && header.content && (
            <div>
                {header.content}
            </div>
        )}
      
        <div className="flex-1 grid grid-cols-12 gap-4 mt-4 overflow-y-visible max-h-screen"> 
            {/* Columna 1: Sidebar */}
            <div className='flex justify-end mr-4 mb-14 col-span-4'>
                <ProfileMenuDesktop />
            </div>


            {/* Columna 2: Contenido principal */}
            {/* <section className={(!container || (container && container.overflow !== false) ? "flex-1 overflow-y-auto" : "") }> */}
            <section className={( "overflow-y-scroll col-span-5" ) }>

                {loading ? (
                    <Loader size="full"/>
                ) : (
                    <>
                        <div className='w-full'>
                            {children}

                        </div>
                    </>
                )}
            </section>

            {/* Columna 3: Ranking */}
            <div className='flex justify-start mr-3 xl:ml-5'>
                <PointRanking />
            </div>
            {/* Aquí se puede agregar contenido adicional en el futuro */}
        </div>

        {footer && footer.content && (
            <div>
            {footer.content}
            </div>
        )}
    </main>
  );
}
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import filesActions from '../../context/files/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getObjectWithJsonDataToFormValues, formatOnlyNumberInput } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import internalsActions from '../../context/internals/actions';
import { capitalizePhrase } from '../../libraries/utils';
import StarRating from 'react-svg-star-rating';
import IconPointsCircle from '../../components/customs/IconPointsCircle';
import IconCoinsCircle from '../../components/customs/IconCoinsCircle';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      selectedFile: null,
      profile_image: null,
      user: null,
      loading: true,
    }
  }
  
  componentDidMount() {
    console.log('USER INFO PAGE', this.props.auth.user);
    this.getUser();    
  }

  getUser = async () => {
    const id = this.props.auth.user.id
    await this.props.onGetUser({id});
    if (this.props.user.error) {
      notify(this.t(this.props.user.error.message));
    } else {
      const rate_total = this.props.user.item.json_data.rate_total
      const rate_count = this.props.user.item.json_data.rate_count
      const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0
      const user = getObjectWithJsonDataToFormValues(this.props.user.item, ['id', 'first_name', 'last_name', 'phone', 'email', 'profile_image']);
      this.setState({ user, rating, loading: false });
    }
  }

  gotoBack = async () => {
    // await this.props.onGetToken();
    if (this.props.auth.error) notify(this.t(this.props.auth.error.message));
    history.push(config.ROUTES.USER.SETTINGS);
  }

  onSubmit = async values => {
    console.log('ON SUBMIT', values);
    this.setState({ submitting: true, loading: true });
    let update_data = { ...values, user_type: config.USER_TYPE.REGULAR}
    // delete update_data.first_name
    // delete update_data.last_name
    await this.props.onUpdate(update_data);
    console.log('AFTER UPDATE');
    const error = this.props.user.error;
    if (error) {
      this.setState({ submitting: false, loading: false });
      notify(this.t(error.message));
    } else {
      let mailData = {
        template: "user_data_update",
        locale: "es",
        to: this.props.auth.user.email,
        first_name: this.props.auth.user.first_name,
        last_name:  this.props.auth.user.last_name,
        params: {
            subject: "Cambio de información personal",
            line_1: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            line_2: `tus datos personales.`,
        }
      }
      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }
      this.gotoBack();
    }
  }

  onFileChange = async event => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    this.setState({ selectedFile: event.target.files[0] });
    await this.onFileUpload(event.target.files[0]);
    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });
  }

  onFileUpload = async (file) => {
    console.log('FILE', file.name, file.type);
    const formData = new FormData();
    formData.append(
      "myFile",
      file,
      file.name
    )
    formData.append("identifier", `profilephoto_${this.props.auth.user.id}`)
    await this.props.onPictureUpload(formData);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ profile_image: this.props.files.files.fileInfo.location });
      this.props.onUpdate({ id: this.state.user.id, profile_image: this.props.files.files.fileInfo.location, user_type: config.USER_TYPE.REGULAR }).then(()=> {
        console.log('AFTER SAVE PICTURE');
        const error = this.props.user.error;
        if (error) notify(this.t(error.message));
      });       
    }
  }

  render() {
    const { user, rating, loading } = this.state;
    console.log(this.props.user.item)
    if (this.state.profile_image) {
      console.log('AVATAR UPLOADED');
      user.profile_image = this.state.profile_image;
    }
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent text-primary-content absolute top-0 left-0 z-20",
          left: { icon: 'arrow_left', action: () => this.gotoBack() }
        }}
        loading={loading}
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <section className="text-center relative h-64 bg-primary-focus">
          <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-60'></div>
          <img src={user?.profile_image} className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' />
          <div className="p-4 relative z-10">
            {user && user.profile_image ? (
              <div className='w-16 h-16 rounded-full mx-auto overflow-hidden'>
                <img className="object-cover h-full w-full" src={user && user.profile_image} alt="" />
              </div>
            ) : (
              <span className="m-auto text-white text-2xl">{user && user.name}</span>
            )}
            <div className="flex justify-center items-center gap-10 mx-auto">
              <div className="rounded-full text-white w-12 h-12 grid">
                <IconCoinsCircle  className="sidebar--points" coins={this.props.auth.user.rewards?.total?.coins}/>
              </div>
              <div>
                <h3 className='text-white'>{capitalizePhrase(this.props.user.item?.name)}</h3>
                <p className='text-center text-white'>Nivel {this.props.rewards.level}</p>
                <label className="cursor-pointer mt-6">
                  <span className="btn btn-secondary btn-sm mt-3">{this.t("Upload a photo")}</span>
                  <input type='file' className="hidden" multiple="multiple" accept="accept" onChange={this.onFileChange} />
                </label>
              </div>
              <div className="rounded-full text-white w-12 h-12 grid">
                <IconPointsCircle className="sidebar--points"  points={this.props.auth.user.rewards?.total?.points} iconClassName="w-5" />
              </div> 
            </div>
            <div className='mx-auto mt-4 gap-2 items-center py-1 px-3 flex border border-white border-opacity-30 rounded-md w-min'>
              <p className='text-sm font-light text-white'>{(rating || 0).toFixed(1)}</p>  <StarRating containerClassName="flex" unit="float" initialRating={rating && rating} size={14} isReadOnly={true}/>
            </div>


          </div>
        </section>
        <section className="px-4 -mt-5">
          <Form 
            initialValues={user || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="card white py-6 z-20">
                  <div className="w-full px-6 mt-1 border-b border-gray-100 md:mb-0">
                    <Field name="first_name" component={TextInput} placeholder={this.t("First name")} label={this.t("First name")}
                      validate={required}
                      className=''
                      inputClassName="px-1 h-8 border-none"
                      labelClassName='p-0'
                    />
                  </div>
                  <div className="w-full px-6 mt-1 border-b border-gray-100 md:mb-0">
                    <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} label={this.t("Last name")}
                      validate={required}
                      className=''
                      inputClassName="px-1 h-8 border-none"
                      labelClassName='p-0'
                    />
                  </div>
                  <div className="w-full px-6 mt-1 border-b border-gray-100 md:mb-0">
                    <Field name="email" component={TextInput} placeholder={this.t("Email")} label={this.t("Email")}
                      validate={composeValidators(required, email)}
                      className=''
                      inputClassName="px-1 h-8 border-none"
                      labelClassName='p-0'
                      parse={ v => v.trim() }
                    />
                  </div>
                  <div className="w-full px-6 mt-1 md:mb-0">
                    <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} label={this.t("Phone number")}
                      parse={v => formatOnlyNumberInput(v,15)}
                      validate={required}
                      className=''
                      inputClassName="px-1 h-8 border-none"
                      labelClassName='p-0'
                    />
                  </div>
                  <div className="w-full px-3">
                    <Button
                      className="btn btn-block btn-secondary"
                      title={this.t("Save")}
                      onClick={handleSubmit}
                      disabled={this.state.submitting || pristine}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutResponsiveWSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    files: state.files,
    user: state.users.current,
    auth: state.users.auth,
    internals: state.internals,
    rewards: state.users.auth.user.rewards,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserInfo));

import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import Icon from '../../libraries/icons';

import config from '../../config';

import { Form, Field } from 'react-final-form'
import logo from '../../assets/img/logo.png';
import { capitalizePhrase, capitalize } from '../../libraries/utils';
import SearchInput from '../forms/SearchInput';
import ButtonIcon from '../commons/ButtonIcon';


import { notify } from '../../libraries/notifications';

export default function SidebarDesktop({ header }) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const settings = useSelector(state => state.settings)
    const dispatch = useDispatch();



    const notifications = useSelector(state => state.notifications.list)
    const unReadedNotifications = notifications.items.filter(n => n.readed === 0 &&
        ["notification"].includes(n.type)).length
    const unReadedMessages = notifications.items.filter(n => n.readed === 0 &&
        ["message", "question"].includes(n.type)).length

    const [menu, setMenu] = useState([])

    const isSelected = (route, location) => {
        if ((location.pathname.includes("favors") || location.pathname.includes("requests")) && route.includes("products")) return true;
        return location.pathname === route
    }


    const filterMenu = () => {
        if (!settings.list.items.length) return
        let menu = config.BUTTONBAR.BOTTOM;
        settings.list.items.forEach(item => {
            if (item.type === "sharyco_settings" && (!item.value || item.value === "false")) {
                if (item.code === "show_actions")
                    menu = menu.filter((section) => section.route !== config.ROUTES.HOME_OPTIONS.ACTIONS)
                if (item.code === "show_offers")
                    menu = menu.filter((section) => section.route !== config.ROUTES.HOME_OPTIONS.PRODUCTS)
            }
        });
        setMenu(menu)
        console.log(menu, '-------------Menu')
    }

    const onSubmit = () => {
        console.log('funcionando')
    }

    useEffect(() => {
        filterMenu()
    }, [settings])
    return (
        <>
            <nav className={"z-50 py-3 px-3 flex items-center h-16 " + (header && header.className ? header.className : "bg-base-100")}>
                <div className='flex-no-wrap flex gap-3 items-center w-full'>
                    {/* <div className='w-12 h-4'>
                        {header && header.left && (
                            <ButtonIcon
                            buttonClassName="btn-ghost bg-gray-200 bg-opacity-70 btn-circle btn-sm hover:bg-gray-400"
                            className={header.left.className ? header.left.className : "h-6 w-6 text-primary"}
                            onClick={header.left.action || (() => true) }
                            icon={header.left.icon}
                            color={header.left.color && header.left.color}
                            />
                        )}

                    </div> */}
                    <div className="w-1/3 flex lg:justify-center xl:justify-end lg:pl-3 xl:pr-20">
                        <img src={logo} alt="" width="193px" />
                    </div>
                    <div className='flex justify-center lg:w-3/6 xl:w-2/5'>
                        <div id='tabs' className='flex justify-center mx-auto'>
                            {menu.map((option, index) => (
                                <Link
                                    key={`sb${index}`}
                                    className={"flex-auto xl:mr-2 p-2 focus:text-primary hover:text-primary h-10 justify-center flex items-center text-center py-3"
                                        + (isSelected(option.route, location) ? " bg-base-200 rounded-2xl" : "opacity-50")
                                    }
                                    to={option.route}
                                >
                                    <div className='indicator items-center'>
                                        <Icon className={"h-6 w-6 mx-auto text-secondary" + (isSelected(option.route, location) ? "" : "text-gray-200")} name={option.icon} />
                                        <span className={' px-2 block text-md ' + (isSelected(option.route, location) ? "text-base-content" : "text-neutral-300")} >
                                            {capitalizePhrase(t(option.label))}
                                        </span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className='lg:mx-auto xl:mx-8 2xl:mx-0'>
                        {header && header.right && (
                            <ButtonIcon
                                buttonClassName={"btn-ghost btn-circle btn-sm justify-self-end " + (header.right.buttonClassName ? header.right.buttonClassName : "")}
                                className={header.right.className ? header.right.className : "h-4 w-4"}
                                onClick={header.right.action}
                                icon={header.right.icon}
                            />
                        )}

                    </div>
                    {/* <Form onSubmit={onSubmit}>
                        {({handleSubmit}) => (
                            <form onSubmit={handleSubmit} className='w-1/3 mb-2 mx-auto flex justify-start items-center mt-3'>
                                <div>
                                    <Field 
                                        name="search"
                                        inputClassName="bg-base-200 text-black font-bold h-8"
                                        className="text-black font-bold rounded-3xl relative py-2"
                                        component={SearchInput}
                                        placeholder={t('Search')}
                                    />
                                </div>
                            </form>
                        )}
                </Form> */}
                </div>

            </nav>
        </>
    )
}

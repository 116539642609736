import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';

import Icon from '../../libraries/icons';
import { Link } from 'react-router-dom';
import { capitalize } from '../../libraries/utils';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';



class Info extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {}
 
  render() {
    const help = config.SIDEBAR.MENU.SECONDARY;
    return (
      <LayoutResponsiveWSmall
        header={{ 
          className: "bg-primary",
          title: capitalize(this.t("help")),
          left: { icon: 'arrow_left', action: () => history.push(this.props.location.state?.from || config.ROUTES.HOME) }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700 ",
        }}
      >
        
        
        <div className="grid grid-cols-2 gap-4 mt-4">
        <div  className="card white p-3 text-center">
            <p className="mb-2">
              <strong>
                <Icon className="block text-primary w-12 mb-2 mx-auto" name="descarga" />
                <a className='mb-2 mx-auto' href={config.URL_MANUAL_USUARIO} download={config.URL_MANUAL_USUARIO}>Descargar Manual</a>
              </strong>
            </p>
          </div>
          { help && help.map((item, index) => {
            console.log("item", item)
            return(
              <div key={index} className="card white p-3 text-center" onClick={() => history.push(item.route)}>
                <p className="mb-2"> <strong ><Icon className="block text-primary w-12 mb-2 mx-auto" name={item.icon} /> {this.t(item.label)} </strong> </p>
              </div>
              )
            }
          )}
        </div>
        
      </LayoutResponsiveWSmall>
    ) 
  }
}

export default withTranslation()(Info);

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import userActions from '../../context/users/actions';
import { notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import UserProfileImage from '../../components/commons/UserProfileImage';
import IconPointsCircle from '../../components/customs/IconPointsCircle';
import IconCoinsCircle from '../../components/customs/IconCoinsCircle';


import transactionsAction from '../../context/transactions/actions';
import productsAction from '../../context/products/actions';
import levelsAction from '../../context/levels/actions';
import companiesActions from '../../context/companies/actions';
import settingsActions from '../../context/settings/actions';

import Button from '../../components/commons/Button';
import StarRating from "react-svg-star-rating";

import InventoryCard from '../../components/customs/InventoryCard';
import ButtonIcon from '../../components/commons/ButtonIcon';
import { capitalizePhrase, capitalize } from '../../libraries/utils';
import Loader from '../../components/commons/Loader';
import medal from '../../assets/img/medal.png';
import Icon from '../../libraries/icons';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: true,
      activities: [],
      userId: this.props.auth.user.id,
      fromPath: null,
      referentUsers: [],
      isPublicProfile: false,
      balances: [],
      creditBalance: 0,
      showing: {
        show_prizes: false,
        show_actions: false,
        show_offers: false,
        show_benefits: false,
      },
      actions: {
        items: [],
        coins: 0,
        points: 0,
      },
      borrows: {
        items: [],
        coins: 0,
        points: 0,
      },
      received: {
        items: [],
        coins: 0,
        points: 0,
      },
    };
  }

  componentDidMount() {
    console.log('PROFILE PAGE', this.props.auth.user);
    const id = this.props.match.params?.id;

    let userId = id ||this.props.auth.user.id
    let isPublicProfile = !!id


    let fromPath = null
    if(this.props.location?.state?.fromPath){
      fromPath = this.props.location.state.fromPath
    }

    this.setState({ userId, isPublicProfile, fromPath }, () => this.getAllData())
    
    if(!this.props.auth.user.ancestors?.company?.name){
      this.getCompany()
    }
  }


  getCompany = async () => {
    await this.props.onGetCompanies({owner: this.props.auth.user.unique_id});
    const { companies } = this.props
     if (companies.error) {
      notify(this.t(companies.error))
    } else {
      if(companies.items.length){
        this.setState({company_name: companies.items[0]?.name})
      }
    }
  }

  getAllData = async () => {
    const { userId: id } = this.state
    this.setState({dataLoading: true})
    
    console.log('PROFILE PAGE ID', id);
    const promises = [
      this.getUser(id),
      this.getRanking(id),
      this.getLevels(),
      this.getRewards(id),
      this.getActivities(id),
      this.getOffers(id),
      this.getInvites(id),
      this.getReferentUsers(id),
      this.getCreditBalance(id),
      this.getBorrowsAndActions(id),
      this.getChallenges(id),
      this.getSettings(id),
    ];

    let d = await Promise.all(promises)
    let state = {dataLoading: false}
    d.forEach(x => {
      if(d.error){
        notify(this.t(x.error.message));
      } else {
        state = {...state, ...x}
      }
    })
    if(state.rewards?.total?.points === "0"){
      state.user_ranking = "N/A"
    }
    this.setState({ ...state, loading: false})
  }

  getOffers = async () => {
    const { userId: id } = this.state

    await this.props.onGetProducts({ owner: id, type: [config.TYPES.OFFERS.PRODUCTS, config.TYPES.OFFERS.SERVICES, config.TYPES.OFFERS.FAVORS]});
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      return {offers: products.items.filter(p => p.status !== "disabled" && p.status !== "borrowed")}
    }
  }

  getUser = async () => {
    const { userId: id } = this.state
    await this.props.onGetUser({id});
    const { user } = this.props
     if (user.error) {
      return {error: this.t(user.error.message)}
    } else {
      const rate_total = user.item.json_data.rate_total
      const rate_count = user.item.json_data.rate_count
      const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0
      return {user: user.item, rating}
    }
  }


  getCreditBalance = async (id) => {
    await this.props.onGetBalance({unique_id: this.props.auth.user.unique_id, user_id: id});
    if (this.props.user.error) {
      return notify(this.t(this.props.user.error.message));
    }
    const userBalance = this.props.user.balance?.credits.balance || 0
    this.setState({ creditBalance: userBalance })
  }


  getBorrowsAndActions = async (id) => {
    await this.props.onGetTransaction({
      type: [
        config.TRANSACTIONS.BORROW.TYPE,
        config.TRANSACTIONS.ACTION.TYPE
      ],
      where: { custom: { source: id, comparison: 'OR',  created_by: id } },
    });
    const { transactions } = this.props;
    
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      const actions = transactions.items.filter(t => t.type === config.TRANSACTIONS.ACTION.TYPE)
      let actionCoins = 0
      let actionPoints = 0
      actions.forEach(a => {
        actionCoins += parseInt(a.json_data.coins) || 0
        actionPoints += parseInt(a.json_data.points) || 0
      })
      const borrows = transactions.items.filter(t => t.type === config.TRANSACTIONS.BORROW.TYPE && t.source === id)
      const received = transactions.items.filter(t => t.type === config.TRANSACTIONS.BORROW.TYPE && t.created_by === id)
      let borrowCoins = 0, borrowPoints = 0, receivedCoins = 0, receivedPoints = 0;
      borrows.forEach(b => {
        borrowCoins += parseInt(b.json_data.coins) || 0
        borrowPoints += parseInt(b.json_data.points) || 0
      })
      received.forEach(b => {
        receivedCoins += parseInt(b.json_data.coins) || 0
        receivedPoints += parseInt(b.json_data.points) || 0
      })

      this.setState({
        actions: {
          items: actions,
          coins: actionCoins,
          points: actionPoints
        },
        borrows: {
          items: borrows,
          coins: borrowCoins,
          points: borrowPoints
        },
        received: {
          items: received,
          coins: receivedCoins,
          points: receivedPoints
        }
      })
    }
  }

  getChallenges = async (id) => {
    // await this.props.onGetTransaction({ type: 'challenge', status: "completed", created_by: id});
    // const { transactions } = this.props;
    // if (transactions.error) {
    //   notify(this.t(transactions.error.message));
    // } else {
    //   const challenges = transactions.items
    //   console.log("CHALLENGES__", challenges)
    // }
  }

  getRanking = async () => {
    const { userId: id } = this.state

    await this.props.onGetUsersRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: this.props.auth.user.unique_id });
    const { ranking } = this.props
    if(ranking?.error){
      return {error: this.t("Error obteniendo el ranking")}
    } else {
      let user_ranking = "N/A"
      console.log("RANKING=============",ranking)
      if(ranking){
        ranking.forEach((r, index) => {
          if(r.id === id){
            user_ranking = index+1;
            this.setState({ prevRank: ranking[index-1]})
          }
        })
      }
      return {user_ranking}
    }
  }
  getReferentUsers = async (referentID) => {
    await this.props.onGetUsers({invited_by: referentID});
    this.setState({
      referentUsers: [...this.props.users.items],
    });
  }

  getInvites = async () => {
    const { userId: id } = this.state

    await this.props.onGetInvites(id);
    const { invites } = this.props;
    if(invites?.error){
      return {error: this.t("Error obteniendo los usuarios referidos")}
    } else {
      console.log("INVITADOS",invites.invites)
      const referralsTotalPoints = invites.invites.reduce((acc, i) => acc + parseInt(i.points), 0)
      return {invites: invites.invites, referralsTotalPoints }
    }
  }
  
  getRewards = async () => {
    const { userId: id, isPublicProfile } = this.state

    await this.props.onGetRewards({id, isPublicProfile});
    if(!isPublicProfile){
      const { rewards } = this.props
      if (!rewards?.total) {
        return {error: this.t("Error obteniendo las recompensas")};
      } else {
        // let user_ranking = "N/A"
        // if(rewards.total.points === "0"){
        //   user_ranking = "N/A"
        // }
        console.log("REWARDS========", rewards.level)
        return {rewards, userLevel: rewards.level}
      }
    }else{
      const { rewards } = this.props.user.item
      console.log(rewards)
      if (!rewards?.total) {
        return {error: this.t("Error obteniendo las recompensas")};
      } else {
        // let user_ranking = "N/A"
        // if(rewards.total.points === "0"){
        //   user_ranking = "N/A"
        // }
        console.log("REWARDS========", rewards.level)
        return {rewards, userLevel: rewards.level}
      }
    }
  }

  getActivities = async () => {
    // if(!id){
    //   id = this.props.auth.user.id
    // }
    const { userId: id } = this.state

    const ownerParams = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
      ],
      status: [
        config.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED,
        config.TRANSACTIONS.ACTION.STATUS.COMPLETED
      ],
      created_by: id
    }

    await this.props.onGetTransaction(ownerParams);
    const { transactions } = this.props;
    let ownerActivities = [ ...transactions.items ]

    if (transactions.error) { 
      return {error: this.t(transactions.error.message)}
    } else {
      const sourceParams = {
        type: config.TRANSACTIONS.BORROW.TYPE,
        status: [
          config.TRANSACTIONS.BORROW.STATUS.ACCEPTED,
          config.TRANSACTIONS.BORROW.STATUS.ON_USER,
          config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,
          config.TRANSACTIONS.BORROW.STATUS.REVIEW,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
        ],
        where: { custom: { source: id, comparison: 'OR',  created_by: id } },
      }
      await this.props.onGetTransaction(sourceParams);
      const { transactions } = this.props;
      if (transactions.error) { 
        return {error: this.t(transactions.error.message)}
      } else {
        let sourceActivities = [ ...transactions.items ]
        let opinionsReceived = sourceActivities.filter(a => a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) && a.source === id)
        let opinionsGiven = sourceActivities.filter(a => a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) && a.created_by === id)

        let totalActivities = ownerActivities.concat(sourceActivities.filter(a => a.source === id).concat(sourceActivities.filter(a => a.created_by === id)))
        console.log("TOTAL ACTIVITIES", totalActivities)
        let rating = (opinionsReceived.reduce((acc, o) => acc + o.json_data.user_rating, 0) / opinionsReceived.length) || 0;
        return {activities: totalActivities, opinionsGiven, opinionsReceived, rating}
      }
  }}


  sendMessage = (user) => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: { fromPath: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', user.id), user }
    });
  } 

  getPointsLeftForNextLevel = () => {
    if(this.state.userLevel === config.MAX_LEVEL) return null
    console.log(this.state.userLevel);
    return this.state.levels[(this.state.userLevel+1).toString()].min - this.props.auth.user.rewards.total.points
  }
 
  getLevels = async () => {
    await this.props.onGetLevels();
    const { levels } = this.props;
    if (levels.error) {
      notify(this.t(levels.error.message));
    } else {
      console.log("LEVELS", levels.item)
      this.setState({ levels: levels.item })
    }  
  }

  goProfileRating = (opinions, type, isPublicProfile, user) => {
    const { userId, rating } = this.state
    history.push({ 
      pathname: config.ROUTES.USER.PROFILE_RATINGS,
      state: { opinions, type, isPublicProfile, userId, rating, user }
    })
  }

  goUserReferrals = (opinions, type, isPublicProfile) => {
    history.push({ 
      pathname: config.ROUTES.USER.REFERRALS,
      state: { fromPath: config.ROUTES.USER.PROFILE }
    })
  }

  goBack = () => {
    const fromPath = this.props.location?.state?.fromPath
    let path = fromPath ? fromPath : config.ROUTES.HOME
    history.push(path)
  }
  
  getSettings = async () => {
    const owner = this.props.auth.user.unique_id
    await this.props.onGetAllSettings({ owner });
    
    const { settings } = this.props;
    if (settings.error) notify(this.t(settings.error.message));
    else {
      const newShowing = this.state.showing;
      this.setState({ settings: settings.items });
      settings.items.forEach((item) => {
        const value = item.value === true || item.value === "true";
        if (item.type === "sharyco_settings") newShowing[item.code] = value;
      });
      this.setState({ showing: newShowing });
    }
  }

  render() {
    const { userId: id, user, activities, rewards, opinionsReceived, opinionsGiven, isPublicProfile, offers, user_ranking, prevRank, userLevel, invites, referralsTotalPoints, referentUsers, creditBalance, actions, borrows, received, rating, loading, showing } = this.state;
    const company_name = this.state.company_name ? this.state.company_name : this.props.auth.user.ancestors?.company?.name
    
    let activityList = [];
    let activityLine1 = {name: this.t("Servicios Comunitarios"), points: 0};
    let activityLine2 = {name: this.t("Acciones Sostenibles"), points: 0};
    let activityLine3 = {name: this.t("Economía Circular"), points: 0};
    
    if (user && rewards) {
      activityList.push({ ...activityLine1, points: rewards.initiative ? rewards.initiative.points : 0 });
      activityList.push({ ...activityLine2, points: rewards.action ? rewards.action.points : 0 });
      activityList.push({ ...activityLine3, points: rewards.borrow ? rewards.borrow.points : 0 });
    }
    if (activityList.length === 0){
      activityList.push(activityLine1);
      activityList.push(activityLine2);
      activityList.push(activityLine3);
    }

    const pointLabels = [
      {label: "Acciones", points: this.props.auth.user.rewards.action.points, quantity: activities.filter(act => act.type ==="action").length},
      {label: "Préstamos", points: this.props.auth.user.rewards.borrow.points, quantity: activities.filter(act => act.type ==="borrow").length},
      // {label: "Desafios", points: this.props.auth.user.rewards.challenges.points},
    ]

    const totalPoints = prevRank?.points - rewards?.total.points + 1

    const favors = offers?.filter(o => o.type === config.TYPES.OFFERS.FAVORS)
    const products = offers?.filter(o => o.type === config.TYPES.OFFERS.PRODUCTS)
    const request = offers?.filter(o => o.type === config.TYPES.OFFERS.REQUESTS)

    return (
      <LayoutResponsive
        main={{ className: ""}}
        header={{ 
          // className: "bg-gradient-to-b from-primary-focus to-transparent bg-transparent text-primary lg:w-full ",
          left: { icon: 'arrow_left', action: this.goBack },
          right: !this.state.dataLoading && (!isPublicProfile ? { icon: 'cog', action: () => history.push(config.ROUTES.USER.SETTINGS) } : id !== this.props.auth.user.id ? { icon: 'chat_alt', action: () => this.sendMessage(user)} : <></> ) 
        }}
        container={{ className: "px-0"}}
        loading={loading}
      >
        {!user && <div className='h-48 grid place-content-center'><Loader spinnerClassName='h-16 w-16 text-primary' /></div>}
        {user && (
          <section className="text-center relative h-64 bg-primary-focus">
            <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-60'></div>
            <img src={user?.profile_image} alt='profile' className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' />
            <div className="p-4 relative z-20 inline-block">
              <UserProfileImage data={user} picture={{ onClick: () => history.push(config.ROUTES.USER.INFO) }}/>
              <div className="flex justify-center items-center gap-10 mx-auto">
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconCoinsCircle  className="sidebar--points" coins={rewards.total.coins}/>
                </div>
                <div>
                  <h3 className=' text-white'>{capitalizePhrase(user?.name)}</h3>
                  <p className='text-center text-white'>Nivel {userLevel && userLevel}</p>
                </div>
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconPointsCircle className="sidebar--points"  points={rewards.total.points} iconClassName="w-5" />
                </div> 
              </div>
              <div className='mx-auto mt-4 gap-2 items-center py-1 px-3 flex border border-white border-opacity-30 rounded-md w-min'>
                <p className='text-sm font-light text-white'>{rating.toFixed(1)}</p>  <StarRating containerClassName="flex" unit="float" initialRating={rating} size={14} isReadOnly={true}/>
              </div>
              
            </div>
          </section>
        )}
        {activities && (
            <section className="px-4 pb-4 -mt-12">
            <div className="card bg-base-content text-center mb-4 z-10">
              <div className="p-3">
                <div className='flex items-center'>
                  <div className='relative -mt-3 mr-2'>
                    <img src={medal} className='w-16' />
                    <p className='absolute bottom-3 right-0 left-0 text-white text-center text-2xl font-bold'>{user_ranking}</p>
                  </div>
                  {/* <div className="bg-white text-primary mr-4 rounded-full w-16 h-16 flex justify-center items-center flex-col">
                    <h2 className='text-4xl'>{this.state.user_ranking}</h2>
                  </div> */}
                  <div className="text-left flex-1">
                    <h5 className='text-white font-light'>Puesto en</h5>
                    <h5 className='text-white font-bold'>{this.props.auth.user.ancestors?.company?.name}</h5>
                    <ButtonIcon
                      buttonClassName="btn-ghost text-secondary btn-xs flex justify-between w-full p-0 tracking-wider mt-1"
                      className=""
                      onClick={()=>history.push(config.ROUTES.USER.RANKING)}
                      text={`Ranking${showing.show_prizes ? " & Premios" : ""}`}
                      icon='arrow_right'
                    />

                  </div>
                </div>
                
                {/* {this.state.user_ranking &&
                  <div className='border-t text-xs text-white text-opacity-30 border-white border-opacity-30 mt-2 pt-1'>
                    Podes llegar al puesto {this.state.user_ranking - 1} con {this.props.ranking[this.state.user_ranking - 2].rewards.total.coins - 
                    this.props.auth.user.rewards.total.coins} puntos!
                  </div>
                } */}
                
                
              </div>
              <div className='border-t border-white border-opacity-20 pb-1'>
                {user_ranking > 1 &&
                  <small className='text-base-300'>
                    ¡Podés alcanzar el {user_ranking - 1}° puesto con { totalPoints !== 1 ? `${totalPoints} puntos` :"1 punto"}!
                  </small>
                }
                {user_ranking === 1 &&
                  <small className='text-base-300'>¡Estás en el primer puesto! Seguí así.</small>
                }
                
              </div>
            </div>
            
            {!isPublicProfile && showing.show_benefits && //&& user?.invited_by === 'referent' 
              <div className="card flex-row align-bottom py-3 bg-yellow-50 border-2 border-white px-6 mb-4 justify-between">
                <div className='flex flex-col'>
                    <span className='text-3xl font-bold text-primary text-center leading-6'>{creditBalance || 0} </span>
                    <span className='text-primary text-center '>Créditos </span>
                </div>
                <div className='flex align-bottom items-end'>
                  <Button 
                    className="px-4 gap-8 btn btn-sm  btn-outline shadow-none text-primary" 
                    title={this.t("Ver historial")}
                    icon={{
                      name: "arrow_right",
                      position: "right"
                    }}
                    onClick={() => 
                      history.push({
                        pathname: config.ROUTES.USER.CREDITS_HISTORY,
                        state: { 
                          from: config.ROUTES.USER.PROFILE,
                          user: isPublicProfile ? this.state.user : null,
                          rating
                        }
                      })
                    }
                  />
                </div>

              </div>
              }

            {!isPublicProfile && //&& user?.invited_by === 'referent' 
              <div className="card py-3 px-6 white mb-4 justify-between flex-row">
                <div className=''>
                  <div className="">
                    <p className="font-light text-primary text-lg">{referentUsers && referentUsers?.length} referidos</p>
                  </div>
                  {referentUsers && (referentUsers.length > 0) && (
                      <ButtonIcon
                      buttonClassName="btn-outline btn-primary shadow-none btn-sm mt-3 mr-auto" 
                      text={this.t("Ver referidos")}
                      className='ml-4 h-4 w-4'
                      icon='arrow_right'
                      onClick={() => this.goUserReferrals()}
                      />
                    )}
                </div>
                <div className='grid place-content-center'>
                  <div className='justify-center flex-col h-20 w-20 items-center flex rounded-full bg-primary bg-opacity-10'>
                    <p className='text-lg font-bold text-primary'>{referralsTotalPoints && referralsTotalPoints}</p>
                    <p className='text-lg font-bold text-primary'>puntos</p>
                  </div>
                </div>
              </div>
            }

            <div className='flex flex-wrap gap-4'>
              { showing.show_actions &&
                <div className="card white text-center mb-4 z-20 flex-1">
                <div className='border-b border-base-300 p-3'>
                  <span className='text-4xl font-bold'>{actions.items.length}</span>
                  <h5>Acciones</h5>
                </div>
                <div className='p-3 flex flex-col items-center justify-center gap-4 md:flex-row'>
                  <div className='flex flex-col items-center'>
                    <IconCoinsCircle name="sharypoints_main" className='bg-none'/>
                    <p className='text-primary'>{actions.coins}</p>
                  </div>
                  <div>
                    <div className='flex flex-col items-center'>
                      <Icon name="impact3" size="1.2rem" />
                      <p className='text-primary lg:mt-2'>{actions.points}</p>
                    </div>

                  </div>
                </div>
                </div>
              }
              {/* <div className="card white text-center mb-4 z-20 flex-1">
                <div className='border-b border-base-300 p-3'>
                  <span className='text-4xl font-bold'>9</span>
                  <h5>Challenges</h5>
                </div>
                <div className='p-3 flex flex-col items-center justify-center gap-4 md:flex-row'>
                  <div>
                    <div className='flex flex-col items-center'>
                      <span>Actions</span>
                      <p className='text-primary'>10pts</p>
                    </div>

                  </div>
                  <div>
                    <div className='flex flex-col items-center'>
                      <span>ODS</span>
                      <p className='text-primary'>10pts</p>
                    </div>
                  </div>
                </div>
              </div> */}
              { showing.show_offers &&
                <div className="card white text-center mb-4 z-20 flex-1">
                <div className='border-b border-base-300 p-3'>
                  <span className='text-4xl font-bold'>{borrows.items.length || 0}</span>
                  <h5>Préstamos</h5>
                </div>
                <div className='p-3 flex flex-col items-center justify-center gap-4 md:flex-row'>
                  <div>
                    <div className='flex flex-col items-center'>
                      <IconCoinsCircle name="sharypoints_main" className='bg-none'/>
                      <p className='text-primary'>{borrows.coins}</p>
                    </div>
                  </div>
                  <div>
                    <div className='flex flex-col items-center'>
                      <Icon name="impact3" size="1.2rem" />
                      <p className='text-primary lg:mt-2'>{borrows.points}</p>
                    </div>

                  </div>
                </div>
                </div>
              }
              { showing.show_offers &&
              <div className="card white text-center mb-4 z-20 flex-1">
                <div className='border-b border-base-300 p-3'>
                  <span className='text-4xl font-bold'>{received.items.length || 0}</span>
                  <h5>Pedidos</h5>
                </div>
                <div className='p-3 flex flex-col items-center justify-center gap-4 md:flex-row'>
                  <div>
                    <div className='flex flex-col items-center'>
                      <IconCoinsCircle name="sharypoints_main" className='bg-transparent'/>
                      <p className='text-error'>{received?.coins && received.coins > 0 ? "-" : ""}{received.coins}</p>
                    </div>
                  </div>
                  <div>
                    <div className='flex flex-col items-center'>
                      <Icon name="impact3" size="1.2rem" />
                      <p className='text-primary lg:mt-2'>{received.points}</p>
                    </div>
                  </div>
                </div>
              </div>
              }

            </div>


            <div className="card white text-center mb-4">
              <div className="border-b border-gray-100 p-3 flex justify-between items-center">
                <h5 className="">{isPublicProfile ? this.t("Historial") : this.t("Tu historial")}</h5>
                <div className='pl-4 flex gap-8'>
                  <Icon name="sharypoints_main" size="1rem" />
                  <Icon name="impact3" size="1rem" />
                </div>
              </div>
                <ul className="p-3 pt-0">
                {
                  activities.slice(0,4).map((activity,index)=> {
                    console.log("activity____", activity)
                    return(
                      activity.type === config.TRANSACTIONS.BORROW.TYPE ? 
                        <li key={index} className="flex items-center gap-3 py-1 border-b border-gray-100">
                          {activity.json_data?.product?.name && <p className="text-neutral mr-auto text-left">{activity.json_data.product.name}</p>}
                          {activity.json_data?.offer?.name && <p className="text-neutral mr-auto text-left">{activity.json_data.offer.name}</p>}
                          <p className='badge badge-ghost badge-sm'>{capitalize(this.t(activity.type))}</p>
                          {(activity.json_data?.product || activity.json_data?.borrower) && <p className=" text-primary text-xs whitespace-nowrap">
                            {activity.json_data?.borrower?.id === id ? '-' : ''} 
                            {activity.json_data?.product?.coins || activity.json_data?.coins} pts
                            </p>}
                          {activity.json_data?.product && <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data?.product?.points} pts</p>}
                          {activity.json_data?.offer && <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data?.offer?.points} pts</p>}
                          
                        </li> 
                      :
                      activity.type === config.TRANSACTIONS.INITIATIVE.TYPE ?
                        <li key={index} className="flex items-center gap-3 py-1 border-b border-gray-100">
                          <p className="text-neutral mr-auto text-left">{activity.json_data.initiative.name}</p>
                          <p className='badge badge-ghost badge-sm'>{capitalize(this.t(activity.type))}</p>
                          <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data.coins} pts</p>
                          <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data.points} pts</p>
                          
                        </li> 
                      :
                        <li key={index} className="flex items-center gap-3 py-1 border-b border-gray-100">
                          <p className='text-neutral mr-auto text-left'>{activity.json_data.action.name}</p>
                          <p className='badge badge-ghost badge-sm'>{capitalize(this.t(activity.type))}</p>
                          <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data.coins} pts</p>
                          <p className=" text-primary text-xs whitespace-nowrap">{activity.json_data.points} pts</p>
                        </li> 
                      )
                    })
                }
              </ul>
              <div className='flex justify-end px-6 pb-2'>
                <Button 
                    className="btn btn-xs btn-outline shadow-none text-primary" 
                    title={this.t("Ver todos")}
                    onClick={() => 
                      history.push({
                        pathname: config.ROUTES.USER.ACTIVITIES,
                        state: { 
                          from: config.ROUTES.USER.PROFILE,
                          user: isPublicProfile ? this.state.user : null
                        }
                      })
                    }
                  />
              </div>
              
            </div>

            <div className="card py-3 px-6 white mb-4 justify-between flex-row">
              <div className=''>
                <div className="">
                  <p className="font-light text-primary text-lg">{opinionsGiven && opinionsReceived && opinionsReceived?.length} comentarios</p>
                  {!!rating && <div className='flex mt-2'>  
                      <StarRating containerClassName="flex" unit="float" initialRating={rating} size={14} isReadOnly={true}/>
                    </div>
                  }
                </div>
                {opinionsGiven && opinionsReceived && (opinionsReceived.length > 0) && (
                    <ButtonIcon
                    buttonClassName="btn-outline btn-primary shadow-none btn-sm mt-3 mr-auto" 
                    text={this.t("Ver comentarios")}
                    className='ml-4 h-4 w-4'
                    icon='arrow_right'
                    onClick={() => this.goProfileRating(opinionsReceived, "received", isPublicProfile, user)}
                    />
                  )}
              </div>
              <div className='grid place-content-center'>
                <div className='justify-center flex-col h-20 w-20 items-center flex rounded-full bg-primary bg-opacity-10'>
                  <StarRating containerClassName="flex" count={1} unit="float" initialRating={1} size={20} isReadOnly={true}/>
                  <p className='text-3xl font-bold text-primary'>{rating?.toFixed(1)}</p>
                </div>
              </div>
            </div>

            {/* <div className="card white mb-4">
              <div className="border-b border-gray-100 p-3 flex justify-between mb-2">
                <h4 className="">{isPublicProfile ? this.t("Opiniones en los pedidos que solicitó") : this.t("Opiniones en tus pedidos")}</h4>
               
              </div>
              <div>
                {opinionsGiven}
              </div>
              {opinionsGiven && opinionsGiven.length > 0 && (
                  <Button 
                    className="btn-link" 
                    title={this.t("Ver todos")}
                    onClick={() => this.goProfileRating(opinionsGiven, "given", isPublicProfile)}
                  />
                )}
            </div> */}

            {offers && isPublicProfile && showing.show_offers && (
              <div className="card white text-center">
                <div className="border-b border-gray-100 p-3 flex justify-between items-center">
                  <h4 className="">{this.t("Ofertas del usuario")}</h4>
                  <ButtonIcon buttonClassName="btn-ghost bg-base-100" className="w-6 h-6 text-neutral" icon="chat_alt"
                    onClick={() => this.sendMessage(user)}
                  />
                </div>
                {offers && offers.length > 0 ?
                  <div className="flex flex-col p-3 mb-5 gap-6">
                    {/* { offers.map((offer, index) => (
                      <InventoryCard 
                        key={index}
                        edit={false}
                        data={offer}
                        fromPath={config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id)}
                        className="p-2 mt-2"
                      />
                    ))} */}
                    {
                      favors && favors.length > 0 &&
                      <div className='flex flex-col gap-2'>
                        <h5 className='text-primary'>{this.t("Favores")}</h5>
                        {favors.map((offer, index) => (
                          <InventoryCard 
                            key={index}
                            edit={false}
                            data={offer}
                            fromPath={config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id)}
                            className="mt-2"
                          />
                        ))}
                      </div>
                    }
                    {
                      products && products.length > 0 &&
                      <div className='flex flex-col gap-2'>
                        <h5 className='text-primary'>{this.t("Productos")}</h5>
                        {products.map((offer, index) => (
                          <InventoryCard 
                            key={index}
                            edit={false}
                            data={offer}
                            fromPath={config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id)}
                            className="mt-2"
                          />
                        ))}
                      </div>
                    }
                    {
                      request && request.length > 0 &&
                      <div className='flex flex-col gap-2'>
                        <h5 className='text-primary'>{this.t("Peticiones")}</h5>
                        {request.map((offer, index) => (
                          <InventoryCard 
                            key={index}
                            edit={false}
                            data={offer}
                            fromPath={config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id)}
                            className="mt-2"
                          />
                        ))}
                      </div>
                    }
                  </div>
                : <p className='text-primary p-3 mb-1'>No hay ofertas dispoibles</p>
              }
                
              </div>
            )}

          </section>
        )}
      </LayoutResponsive>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    users: state.users.list,
    auth: state.users.auth,
    transactions: state.transactions.list,
    rewards: state.users.auth.user.rewards,
    ranking: state.users.list.ranking,
    products: state.products.list,
    levels: state.levels.current,
    companies: state.companies.list,
    invites: state.users.invites,
    settings: state.settings.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetUsers: params => dispatch(userActions.getAll(params)),
    onGetUsersRanking: (params) => dispatch(userActions.getRanking(params)),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onGetBalance: (params) => dispatch(userActions.getBalance(params)),
    onLogout: () => dispatch(userActions.logout()),
    onGetTransaction: (params) => dispatch(transactionsAction.getAll(params)),
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetLevels: (params) => dispatch(levelsAction.get(params)),
    onGetCompanies: (params) => dispatch(companiesActions.get(params)),
    onGetInvites: (params) => dispatch(userActions.getInvites(params)),
    onGetAllSettings: (params) => dispatch(settingsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserProfile));


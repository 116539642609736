import types from './types';
import users from './services';
import oauth from '../oauth/actions'

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const login = (user, from) => {
  console.log('ACTIONS::USERS::login', user, from);

  const request = (user) => { return { type: types.LOGIN_REQUEST, user } };
  const success = (payload) => { return { type: types.LOGIN_SUCCESS, payload } };
  const failure = (error) => { return { type: types.LOGIN_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request(user));
    let response = await users.login(user, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success({ token: response.data.token, user: response.data.user }));
    } else {
      // users.removeLocalAccessToken();
      if (response.error && response.error.code === 'ERROR_401') {
        console.log('APP TOKEN NOT VALID');
        dispatch(oauth.removeToken());
        dispatch(oauth.accessToken());
      } else {
        dispatch(failure(response.error));
      }
    }
  }
}

const getFromToken = () => {
  console.log('ACTIONS::USERS::getFromToken');

  const request = (username) => { return { type: types.LOGIN_REQUEST, username } };
  const success = (payload) => { return { type: types.LOGIN_SUCCESS, payload } };
  const failure = (error) => { return { type: types.LOGIN_FAILURE, error } };

  return async dispatch => {
    dispatch(request('token'));    
    const token = users.getToken();
    console.log('USER TOKEN FROM LOCALSTORAGE', token);
    if (token) {
      // dispatch(success({ token }));
      const response = await users.verifyToken(token);
      console.log('RESPONSE FROM SERVIVE', response);
      if (response.success) {
        dispatch(success({ token: response.data.token, user: response.data.user }));
      } else {
        dispatch(failure(response.error));
      }

    } else {
      dispatch(failure());
    }
  }
}

const getFromSocial = (params) => {
  console.log('ACTIONS::USERS::getFromSocial');

  const request = (username) => { return { type: types.LOGIN_REQUEST, username } };
  const success = (payload) => { return { type: types.LOGIN_SUCCESS, payload } };
  const failure = (error) => { return { type: types.LOGIN_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request('social'));    
    const response = await users.getFromSocial(params, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success({ token: response.data.token, user: response.data.user }));
    } else {
      dispatch(failure(response.error));
    }
  }
}

const loginSSO = () => {
  console.log('ACTIONS::USERS::login SSO');

  const request = (username) => { return { type: types.LOGIN_REQUEST, username } };
  const success = (payload) => { return { type: types.LOGIN_SUCCESS, payload } };
  const failure = (error) => { return { type: types.LOGIN_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request('SSO'));
    let response = await users.loginSSO(getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success({ token: response.data.token, user: response.data.user }));
    } else {
      // users.removeLocalAccessToken();
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const logout = () => {
  console.log('ACTIONS::USERS::logout');
  users.logout();
  return { type: types.LOGOUT };
}

const forgot = (params) => {
  console.log('ACTIONS::USERS::forgot');
  const request = () => { return { type: types.FORGOT_REQUEST } };
  const success = (payload) => { return { type: types.FORGOT_SUCCESS, payload } };
  const failure = (error) => { return { type: types.FORGOT_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request()); 
    const response = await users.forgot(params, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }    
  }
}

const verifyPIN = (params) => {
  console.log('ACTIONS::USERS::verifyPIN');

  const request = () => { return { type: types.VERIFY_REQUEST } };
  const success = (payload) => { return { type: types.VERIFY_SUCCESS, payload } };
  const failure = (error) => { return { type: types.VERIFY_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request()); 
    const response = await users.verifyPIN(params, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }       
  }
}

const verifyPINretry = (params) => {
  console.log('ACTIONS::USERS::verifyPINretry');

  const request = () => { return { type: types.VERIFY_RETRY_REQUEST } };
  const success = (payload) => { return { type: types.VERIFY_RETRY_SUCCESS, payload } };
  const failure = (error) => { return { type: types.VERIFY_RETRY_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request()); 
    const response = await users.verifyPINretry(params, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }       
  }
}

const getFromHash = (params) => {
  console.log('ACTIONS::USERS::getFromHash');

  const request = (username) => { return { type: types.GET_REQUEST, username } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } };
  const failure = (error) => { return { type: types.GET_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request('hash'));    
    const response = await users.getFromHash(params, getState().oauth.token);
    console.log('RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  }
}

const getAll = (params) => {
  console.log('ACTIONS::USERS::getAll', params);

  function request() { return { type: types.GETALL_REQUEST } };
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } };
  function failure(error) { return { type: types.GETALL_FAILURE, error } };

  return async (dispatch, getState) => {
    console.log('ACTIONS::USERS::getAll', params);
    dispatch(request());
    const response = await users.getAll(params, getState().users.auth.token);
    console.log('ACTIONS::USERS::getAll::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const get = (params) => {
  console.log('ACTIONS::USERS::get', params);

  const request = (params) => { return { type: types.GET_REQUEST, params } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } };
  const failure = (error) => { return { type: types.GET_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request(params));
    const token = !params.id ? getState().oauth.token : getState().users.auth.token;

    const response = await users.get(params, token);
    console.log('ACTIONS::USERS::get::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const saveOrUpdate = (user, action = 'save') => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } };
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } };
  function failure(error) { return { type: types.SAVE_FAILURE, error } };

  return async (dispatch, getState) => {
    console.log('ACTIONS::USERS::saveOrUpdate', user);
    dispatch(request(user));

    const token = action === 'register' ? getState().oauth.token : getState().users.auth.token;
    let response;
    if (user.id) {  // editing a existing record
      response = await users.update(user, action, token);
    } else {
      response = await users.save(user, action, token);
    }
    console.log('ACTIONS::USERS::saveOrUpdate::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const del = (params) => {
  console.log('ACTIONS::USERS::del', params.id);
 
  function request(id) { return { type: types.DELETE_REQUEST, id } };
  function success(id) { return { type: types.DELETE_SUCCESS, id } };
  function failure(id, error) { return { type: types.DELETE_FAILURE, id, error } };

  return async (dispatch, getState) => {
    dispatch(request(params.id));
    let response = await users.del(params, getState().users.auth.token);
    console.log('ACTIONS::USERS::del::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(params.id));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const getRewards = ({id, isPublicProfile= true}) => {
  console.log('ACTIONS::USERS::getRewards', id);

  const request = (id) => { return { type: types.GETREWARDS_REQUEST, id } };
  const success = (payload) => { return { type: isPublicProfile ? types.GETREWARDS_SUCCESS : types.GETAUTHREWARDS_SUCCESS, payload } };
  const failure = (error) => { return { type: isPublicProfile ? types.GETREWARDS_FAILURE : types.GETAUTHREWARDS_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request(id));
    const response = await users.getRewards({id}, getState().users.auth.token);
    console.log('ACTIONS::USERS::getRewards::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const getRanking = (params) => {
  console.log('ACTIONS::USERS::Ranking', params);

  function request() { return { type: types.GETRANKING_REQUEST } };
  function success(payload) { return { type: types.GETRANKING_SUCCESS, payload } };
  function failure(error) { return { type: types.GETRANKING_FAILURE, error } };

  return async (dispatch, getState) => {
    console.log('ACTIONS::USERS::GETRANKING', params);
    dispatch(request());
    const response = await users.getRanking(params, getState().users.auth.token);
    console.log('ACTIONS::USERS::GETRANKING::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const getInvites = (id) => {
  console.log('ACTIONS::USERS::getInvites', id);

  const request = (id) => { return { type: types.GETINVITES_REQUEST, id } };
  const success = (payload) => { return { type: types.GETINVITES_SUCCESS, payload } };
  const failure = (error) => { return { type: types.GETINVITES_FAILURE, error } };

  return async (dispatch, getState) => {
    dispatch(request(id));
    const response = await users.getInvites(id, getState().users.auth.token);
    console.log('ACTIONS::USERS::getInvites::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  }
}

const getBalance = (params) => {
  console.log("ACTIONS::USERS::Balance", params);

  function request() {
    return { type: types.GETBALANCE_REQUEST };
  }
  function success(payload) {
    return { type: types.GETBALANCE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.GETBALANCE_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log("ACTIONS::USERS::GETBALANCE", params);
    dispatch(request());
    const response = await users.getBalance(
      params,
      getState().users.auth.token
    );
    console.log("ACTIONS::USERS::GETBALANCE::RESPONSE FROM SERVIVE", response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const actions = {
  clearCurrent,
  login,
  logout,
  forgot,
  verifyPIN,
  verifyPINretry,
  saveOrUpdate,
  del,
  get,
  getAll,
  getFromToken,
  getFromHash,
  getRewards,
  getFromSocial,
  loginSSO,
  getRanking,
  getInvites,
  getBalance,
};

export default actions
import { Component } from 'react'
import {  withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom';
import config from '../../config';

import Loader from '../../components/commons/Loader';
import { ToastContainer } from 'react-toastify';
import { notify } from '../../libraries/notifications';
import challengesActions from "../../context/challenges/actions";
import ChallengesWrapper from './ChallengesWrapper'

import clsx from 'clsx';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: [],
      loading: true,
    }
  }

  getActivities = async () => {
    const { id } = this.props.match.params
    await this.props.getActivity(id)
    if (this.props.activity.error) {
      notify(this.props.activity.error.message)
      return
    }
    const filteredActivity = this.props.activity?.filter(act => act.json_data.points !== 0)
    this.setState({ activity: filteredActivity })
    this.setState({ loading: false })
  }


  componentDidMount() {
    this.getActivities()
  }

  render() {
    const { activity, loading } = this.state
    const { item: challenge } = this.props.challenge
    console.log(activity)
    return (
      <ChallengesWrapper>
        <ToastContainer />
        <div className='flex flex-col gap-2'>
          {loading && <Loader/>}
          {!loading && activity && !activity.length && <p>Este desafío no tiene actividad todavía.</p>}
          {challenge.condition_type === "actions" && "En este desafío se puntúa con la cantidad de acciones realizadas (1 acción = 1 punto)"}
          {challenge.condition_type === "points" && "En este desafío se puntúa con la cantidad de puntos de impacto de las acciones realizadas"}
          {!loading && activity && activity.map(trx => {
              if(trx.type === "action") return <ActionActivityCard key={trx.id}  trx={trx} challenge={challenge} />
              if(trx.type === "borrow") return <BorrowActivityCard key={trx.id}  trx={trx} challenge={challenge} />
              return
            }
            )
          }
        </div>
      </ChallengesWrapper>
    )
  }
}


const ActionActivityCard = ({trx, challenge}) => {
  const actionOds = trx.json_data?.action?.ods?.split(',');
  let odsIcons = [];
  if (actionOds) {
    actionOds.forEach( o => {
      if (config.ODS.find( e => e.code === o.trim() )) {
        odsIcons.push(config.ODS.find( e => e.code === o.trim() ).icon)
      }
    });
  }
  // const condition_type = "points"
  return (
    <div
      className={clsx('w-full p-3 flex gap-2 items-center border rounded-lg bg-white',
        trx.archived === 1 && "grayscale"
      )}
    >
      <div className='w-14 h-12 self-start rounded-lg'> 
        <img src={trx.json_data.action.img} alt="" className="w-full h-full object-cover" />
      </div>
      <div className='flex-1'>
        <h6 className='capitalize'>{`${trx.json_data.user.first_name} ${trx.json_data.user.last_name}`}</h6>
        <p className='max-w-[60ch] flex-shrink'>realizó la acción: {trx.json_data.action.name}</p>
        <div className='flex w-full justify-between items-center mt-1'>
          {/* {condition_type === "points" && <span className='font-bold text-secondary-focus'>+{trx.json_data.action?.points}pts</span>}
          {condition_type === "actions" && <span className='font-bold text-secondary-focus'>+1 pts</span>} */}
          <span className='font-bold text-secondary-focus'>+{trx.json_data.action?.points}pts</span>
          <div className='avatar-group right-2 overflow-visible'>
            {odsIcons && odsIcons.slice(0,3).map( (o, i) => (
              <div key={i} className='-ml-2'>
                <img src={o} alt='ods' className='w-6 h-6' />
              </div>
            ))}
            {odsIcons && odsIcons.length > 3 && (
              <div className='text-secondary rounded-full -ml-2 w-6 h-6 bg-primary-focus grid place-content-center'>
                <span className='-ml-1 flex text-sm font-bold'>+{odsIcons.length - 3}</span>
              </div>
            )}
          </div>
        </div>
        
      </div>
    </div>
  )
}

const BorrowActivityCard = ({trx, challenge}) => {
  let type = (trx.sub_type === "product") ? "product" : "offer"

  return (
    <div
      className={clsx('w-full p-3 flex gap-2 items-center border rounded-lg bg-white',
        trx.archived === 1 && "grayscale"
      )}
    >
      <div className='w-14 h-12 self-start rounded-lg'>
        <img src={trx.json_data[type].img} alt="" className='w-full h-full object-cover' />
      </div>
      <div className='flex-1'>
        <h6 className='capitalize'>{`${trx.json_data.borrower.first_name} ${trx.json_data.borrower.last_name}`}</h6>
        <div className='flex w-full justify-between'>
          <p className='max-w-[60ch] flex-shrink'>Prestó: {trx.json_data[type].name}</p>
          <div className='w-6 h-6 p-1 flex-shrink-0 bg-green-800 rounded-full '>
          </div>
        </div>
        <span className='text-sm font-bold text-yellow-400'>+{1}pts</span>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    // activities: state.activities.list,
    // activities:state.challenges.activities.list,
    activity: state.challenges.current.activity,
    challenge: state.challenges.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActivity: (id) => dispatch(challengesActions.getActivity(id)),
  };
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Activity)))
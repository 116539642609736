import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';

import { getOwner } from '../../libraries/utils';

import config from '../../config';
import Icon from '../../libraries/icons';
import BottomNav from '../../components/commons/BottomNav';
import NotifyElement from '../../components/customs/NotifyElement';

import messagesActions from '../../context/messages/actions';
import productsAction from '../../context/products/actions';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Notifications extends React.Component {

  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      messages: [],
    }
  }
  
  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    await this.getUserMessages();
    this.markAllNotificationsAsReaded();
  }

  getUserMessages = async () => {
    await this.props.onGetAllMessages({ type: config.MESSAGES.TYPE.NOTIFICATION, target: this.props.auth.user.id, order_direction: "DESC" })
    const { messages } = this.props;
    if (messages.error) {
      notify(messages.error.message);
    } else {
      this.setState({ messages: messages.items });
    }
  }

  getNotificationType =  async (message) => {
    await this.props.onGetProduct({ id: message.related_to })
    const { product } = this.props;
    if (product.error) {
      notify(product.error.message);
    } else {
      this.setState({ product: product.item });
    }
  }

  markNotificationAsReaded = async (id) => {
    await this.props.onSaveMessage({ id, readed: 1  });
    const message = this.props.message;
    if (message.error) {
      notify(this.t(message.error.message));
      return null
    } else {
      return message.item.id
    }
  }

  markAllNotificationsAsReaded =  async () => {
    let unreadedNotifications = this.state.messages.filter(m => m.readed === 0)

    if(unreadedNotifications.length){
      let promises = [];
      for (let i = 0; i < unreadedNotifications.length; i++) {
        promises.push(this.markNotificationAsReaded(unreadedNotifications[i].id))
      }

      let ids = []
      let upload_range = 30 //mas de 40 al mismo tiempo tira error
      for (let i = 0; i < promises.length; i += upload_range) {
        let ids_ = await Promise.all(promises.slice(i, i+upload_range))
        ids = [...ids_, ...ids]
      }
      console.log("ids", ids)

    }
  }


  

  render() {
    const { messages, product } = this.state;

    return (
      <LayoutResponsive
        main={{ className: ""}}
        header={{ 
          className: "",
          title: this.t("Notifications"),
          // right: { icon: 'arrow_left', action: ()=> history.push(config.ROUTES.HOME), className: 'z-20 h-6 w-6', buttonClassName: ' ml-auto' },
        }}
      >
        <ToastContainer/>
        <section className="pb-10 p-3 w-full mx-auto">

          {!this.props.messages.loading && messages.length === 0 && (
            <div className="text-center p-10">
              <Icon className="h-24 w-24 mx-auto mb-3" name="bell" />
              <h4 className="mb-1">{this.t("No tienes notificaciones")}</h4>
            </div>
          )}
          <div className="bg-white">
            { messages && messages.map((message, index) => {
              // console.log("message", messages)
              const body = message.body;
              const p = message.json_data;
              let unsafeRoute

              if(body.includes('te pidió')) unsafeRoute = config.ROUTES.USER.LOANS;
              else if(body.includes('tu pedido')) unsafeRoute = config.ROUTES.USER.RETURNS;
              else if(body.includes('tu servicio')) unsafeRoute = config.ROUTES.USER.RETURNS;
              else if(p.product || p.favor) unsafeRoute = config.ROUTES.USER.LOANS;
              
              if(p.service) unsafeRoute = config.ROUTES.SERVICES.DETAIL.replace(':id', p.service.id)

              return(
                <NotifyElement
                  key={index}
                  data={message}
                  className="border-b border-b-gray-100"
                  type = "received"
                  //route={{ pathname: message.json_data && message.json_data.product && (message.json_data.product.owner === this.props.auth.user.id ? config.ROUTES.USER.LOANS : config.ROUTES.USER.RETURNS), state: { from: config.ROUTES.NOTIFICATIONS, message }}}
                  route={{ pathname: p.redirect_notification_route || unsafeRoute, state: { fromPath: config.ROUTES.NOTIFICATIONS, from: config.ROUTES.NOTIFICATIONS, message, subtype: Object.keys(p)[0] }}}
                />
              )
            }  
            )}
          </div>
        </section>
        {/* <BottomNav/> */}
      </LayoutResponsive>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    messages: state.messages.list,
    message: state.messages.current,
    product: state.products.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllMessages: (params) => dispatch(messagesActions.getAll(params)),
    onGetProduct: (params) => dispatch(productsAction.get(params)),
    onSaveMessage: (params) => dispatch(messagesActions.saveOrUpdate(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Notifications));
   

  

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { validateIsfilled } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextWithIconInput from '../../components/forms/TextWithIconInput';
import internalsActions from '../../context/internals/actions';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      showPassword: false,
      showNewPassword: false,
      showNewPassword2: false,
      user: null,
    }
  }
  
  componentDidMount() {
    console.log('PASSWORD CHANGE PAGE', this.props);
    this.getUser();
  }

  getUser = async () => {
    console.log('GETTING USER');
    await this.props.onGetUser({ id: this.props.auth.user.id });
    console.log('AFTER GET');
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ user: this.props.user.item });
    }
  }

  onSubmit = async values => {
    const user = this.state.user;
    this.setState({ submitting: true });
    await this.props.onUpdate({ password: values.password, newpassword: values.newpassword, id: user.id,  })
    if (this.props.user.error) {
      this.setState({ submitting: false });
      notify(this.t(this.props.user.error.message));
    } else {
      let mailData = {
        template: "user_data_update",
        locale: "es",
        to: this.props.auth.user.email,
        first_name: this.props.auth.user.first_name,
        last_name:  this.props.auth.user.last_name,
        params: {
            subject: "Cambio de contraseña",
            line_1: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            line_2: `tu contraseña.`,
        }
      }
      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }
      history.push(config.ROUTES.USER.PROFILE);
    }    
  }
 
  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword});
  }

  onNewPasswordClick = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword});
  }

  onNewPasswordClick2 = () => {
    this.setState({ showNewPassword2: !this.state.showNewPassword2});
  }

  render() {
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"));
    const validate = (values) => { 
      let errors = [];
      if (values.newpassword !== values.newpassword2) errors.newpassword2 = this.t("Passwords must match");
      return errors;
    };
    return (
      <LayoutResponsiveWSmall
        main={{ className: "light-primary-bg text-primary-100"}}
        header={{
          title: this.t("Change password"),
          className: "bg-primary text-primary-100",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.USER.PROFILE) }
        }}
        container={{ className: "px-0"}}
        loading={this.props.user.loading}
      >
        <ToastContainer/>
        <section className="px-4 mt-5">
          <Form onSubmit={this.onSubmit} validate={validate} >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="card white py-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="password" component={TextWithIconInput} placeholder={this.t("Password")}
                      label={this.t("Current password")}
                      className="text-gray-700"
                      showPassword={this.state.showPassword}
                      clickPassword={this.onPasswordClick}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="newpassword" component={TextWithIconInput} placeholder={this.t("New password")}
                      label={this.t("New password")}
                      className="text-gray-700"
                      showPassword={this.state.showNewPassword}
                      clickPassword={this.onNewPasswordClick}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3 mb-10 md:mb-0">
                    <Field name="newpassword2" component={TextWithIconInput} placeholder={this.t("Repite new password")}
                      className="text-gray-700"
                      showPassword={this.state.showNewPassword2}
                      clickPassword={this.onNewPasswordClick2}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3">
                    <Button
                      className="btn btn-lg btn-block btn-secondary"
                      title={this.t("Confirm")}
                      onClick={handleSubmit}
                      disabled={this.state.submitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutResponsiveWSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params, 'update')),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PasswordChange));
